<template>
  <div class="production_wrap">
    <div class = "box">
      <div class="about-title">组织架构</div>
      <div class = "block-line"></div>
      <div class="about-container">
        <p style="text-indent:3em;line-height:3;;text-align:justify;">
          <span style="font-size:16px;">空间规划公司党总支下设9个基层党支部，共有党员64人。在上级党组领导下，持续加强和改进党建工作，坚持政治领导、思想领导、组织领导的有机统一。努力推动企业深化改革、提高经营管理水平，加强国有资产监管。</span> 
        </p>
        <p style="text-indent:3em;line-height:3;;text-align:justify;">
          <span style="font-size:16px;">按照公司章程，空间规划公司设董事会、监事会。空间规划公司下设数字规划研究分院、高新分院、城市更新分院等5个分院，18个生产部门和2个行政部门。</span> 
        </p>
        <p style="text-indent:3em;line-height:3;;text-align:justify;">
          <span style="font-size:16px;">目前，空间规划公司下属二级托管大连工程咨询中心有限公司、三级全资子公司大连市交通设计研究院有限公司。</span> 
        </p>
        <img width="100%" style="margin-top: 10px" src="../../assets/htmlimg/公司构架.png" alt="" />
      </div>
    </div>
    <CallMe />
    <MobileCallme />
  </div>
</template>

<script>
import CallMe from '@/components/callme/index'
import MobileCallme from '@/components/callme/MobileCallme.vue'
export default {
  data() {
    return {
    }
  },
  components: {
    CallMe,
    MobileCallme,
  },
}
</script>

<style lang="scss" scoped>
.production_wrap {
  padding-top: 200px;
  width: 100%;
}
</style>